<template>
	<footer class="footer" :class="{ 'footer-small-height': !isMainPage }">
		<div class="footer-container">
			<div class="footer-top">
				<div class="footer-content">
					<div class="footer-links">
						<!--						<a target="_blank" :href="getTermsUrl(6)">이용약관</a>-->
						<!--            <a target="_blank" :href="getTermsUrl(7)">개인정보처리방침</a>-->
						<a href="javascript:void(0)" @click="openTermModal(2)">이용약관</a>
						<a href="javascript:void(0)" @click="openTermModal(100)">개인정보처리방침</a>
					</div>
				</div>
				<div class="footer-info" v-if="!isMainPage">Copyright ⓒ LG Uplus Corp. All Rights Reserved.</div>

				<!--
				<div class="footer-button">
					PSelect
						type="select"
						v-model="selected"
						:options="groupedCities"
						optionLabel="label"
						optionGroupLabel="label"
						optionGroupChildren="items"
						placeholder="바로가기"
					/>
				</div>
			-->
			</div>
			<div class="footer-bottom" v-if="isMainPage">
				<div class="footer-info">
					<p>
						㈜엘지유플러스 | 서울특별시 용산구 한강대로 32 <span class="only-pc"> | </span> 대표이사 황현식<br
							class="only-mobile"
						/>
						<span class="only-pc"> | </span> 사업자등록번호 220-81-39938 <br class="only-pc" />
						통신판매업신고 제 2015-서울용산-00481호 <span class="only-pc"> | </span><br class="only-mobile" />
						AlphaKey 고객센터 1544-2777(유료)
					</p>
					<p>Copyright ⓒ LG Uplus Corp. All Rights Reserved.</p>
				</div>
				<div class="footer-logo">
					<nuxt-link to="/">
						<img src="@/assets/images/footer-logo.svg" alt="LG U+ Logo" @click="toTop('/')" />
					</nuxt-link>
				</div>
			</div>
		</div>
	</footer>
</template>
<script setup lang="ts">
import PSelect from '~~/home/components/PSelect.vue'
import AuthService from '~~/home/services/auth/AuthService'
import { onMounted, computed } from 'vue'
import type { TermListRequestType, TermsDetailRequestType, TermsDetailResponseType } from '~~/home/types/auth/auth'
const authService = new AuthService()
const { $router } = useNuxtApp()
const { Dialog, Alert, AlertWithAction } = useDialogs()
const groupedCities = ref([
	{
		items: [{ label: '바로가기', code: 'Berlin', value: 'Berlin' }]
	}
])
const route = useRoute()

const terms = ref<TermsDetailResponseType[]>([])

const isMainPage = computed(() => {
	let uri = route.path
	console.log('[footer] uri:', uri)
	return !(uri.startsWith('/auth/') || uri.startsWith('/my/'))
})

// onMounted(async () => {
// 	await getTerms(8)
// 	await getTerms(16)
// })

const getTerms = async (termType: number) => {
	try {
		const termRequest: TermsDetailRequestType = {
			termType
		}
		const termsResponse = await authService.getTermsDetail(termRequest)
		if (termsResponse?.data) {
			terms.value.push(termsResponse.data)
		}
		return termsResponse.data
	} catch (e) {
		console.error(e)
	}
}

const getTermsUrl = (termType: number) => {
	const t = terms.value.find((t) => t.termType === termType)
	return t ? t.termUrl : '#'
}

const toTop = (to: string) => {
	// window.scrollTo({ top: 0 })
	if (to === route.path) {
		// 같은 경로로 이동하려는 경우 강제로 새로고침
		window.location.reload()
	} else {
		// 다른 경로로 이동
		$router.push(to)
	}
}

const openTermModal = async (termType: number) => {
	const t = await getTerms(termType)
	const modal = defineAsyncComponent(() => import('~~/home/pages/main/terms/commonTerms.vue'))
	Dialog(modal, {
		props: {
			header: t?.termTitle
		},
		// data: {
		// 	termsUrl: t?.termUrl
		// },
		data: {
			termData: t
			// termType: t?.termType
		},
		onClose: (event: { data?: { reason?: string } }) => {
			console.log('TEST')
		}
	})
}
</script>

<style scoped lang="scss">
// .footer-small-height {
// 	height: 114px;
// }
.footer-logo {
	cursor: pointer;
}
</style>
